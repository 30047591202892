<template>

  <div>
    <br>
    <div class="demo">
    <!-- <button @click="init">initialize</button> -->
    <button @click="animate">animate</button>
    Data scale - min: <input type="number" v-model.number="dataMin" @input="setScale" />
     max: <input type="number" v-model.number="dataMax" @input="setScale" />  

     <!-- {{covered}}% within red area -->
    </div>

    <div class="recorder">
      sample rate: <input type="number" style="width:50px;" v-model="sampleRate" />
      <button @click="startRecording" :disabled="recorderInterval">Record</button>
      <button @click="stopRecording" :disabled="!recorderInterval">Stop recording</button>      
      <button @click="replayRecording" :disabled="recorderInterval || !recordingX.length || !recordingY.length">Replay</button>      
      <input type="checkbox" v-model="drawScanpath" /> plot scanpath
      <input type="checkbox" v-model="drawMotionTrail" /> motion trail          
      <button @click="getInternalData">internal</button>
      gazeX: {{gazeX}} gazeY: {{gazeY}}
    </div>

    <div class="loader">
      <button @click="loadRecordings">Load recording(s) from textarea</button>
      {{recordings.length}} loaded
      <button @click="replayRecordings" :disabled="recorderInterval || !recordings.length">Replay loaded</button>  
      <input type="checkbox" v-model="slidingWindow" /> sliding window <input type="number" :disabled="!slidingWindow" style="width:40px;" v-model="slidingWindowSamples" /> ({{Math.round(slidingWindowSamples/sampleRate * 1e3) / 1e3}} seconds)
      ---
      <button @click="downloadRecordings">Load recording(s) from S3</button>
      session: <input type="number" v-model="sessionId"/>
      segment: <input type="number" v-model="segmentId"/>
      item: <input type="number" v-model="itemId"/>
    </div>

    <textarea id="testJson" />

    <media-controller class="mediacontroller">
      <video slot="media" ref="video" :class="{recording: recorderInterval}" :src="videoSource" width="600" id="video">
        <!-- <source src="../assets/demovideo2.mp4" type="video/mp4"> -->
        <!-- <source src="../assets/BC_LR_01.mp4" type="video/mp4"> -->
        <!-- <source src="https://s3.eu-central-1.amazonaws.com/dev.public.avris.io/balint-test1/BC_LR_01.mp4" type="video/mp4"> -->
        Sorry, your browser doesn't support embedded videos.
      </video>
      <media-control-bar>
        <media-play-button></media-play-button>
        <!-- <media-mute-button></media-mute-button>
        <media-volume-range></media-volume-range> -->
        <media-time-range></media-time-range> 
        <media-seek-backward-button/>
        <media-seek-forward-button/>
        <media-time-display show-duration></media-time-display>    
      </media-control-bar>
    </media-controller>

    <canvas id="scanpathCanvas" width="600" height="250" :class="{recording: recorderInterval}" />
    <canvas id="motionTrailCanvas" width="600" height="250" :class="{recording: recorderInterval}" />

    <div class="heatmap" @click="addDataPoint" :class="{recording: recorderInterval}">      
    </div>

    <!-- <div id="area1"></div> -->

    <div class="areas" ref="areas">
      <vue-draggable-resizable v-for="(area, index) in areas" :key="area.label" 
        class="area" 
        :w="area.w" 
        :h="area.h" 
        :x="area.x" 
        :y="area.y" 
        @dragging="onAreaDrag" 
        @resizing="onAreaResize" 
        @resizestop="recalcArea"
        @dragstop="recalcArea"
        :parent="true"
        :data-areaindex="index"
      >
        <span class="label">{{area.label}}</span>        
        <span v-if="labelPercentages" class="label">{{area.liveCoverage.toFixed(1)}}%</span>  
        <br v-if="labelPercentagesOverall">      
        <span v-if="labelPercentagesOverall" class="label">Overall {{area.aggregatedCoverage.toFixed(1)}}%</span>        
      </vue-draggable-resizable>
    </div>

    <div class="arealist">
      <button @click="addArea">Add area</button>      
      <button @click="aggregateAreaCoverage">Calc overall</button>
      <br>
      <input type="checkbox" v-model="labelPercentages" /> live labels
      <input type="checkbox" v-model="labelPercentagesOverall" /> overall labels
      <div v-for="(area, index) in areas" :key="index">
        <input type="text" v-model.trim="area.label">
        <button @click="removeArea(index)">delete</button>
        sliding {{area.liveCoverage.toFixed(1)}}%, overall: {{area.aggregatedCoverage.toFixed(1)}}%
      </div>
      <br>
      <!-- <span style="font-size:12px; color: grey;"><b>Note:</b> in this demo, the area of the heatmap (green) and the video differs, for accurate numbers this will require custom video controls to be implemented outside the default video player, so the heatmap will not cover the controls.</span> -->
    </div>

    <div class="userlist">    
      Time spent in areas per user:  
      <br>  
      <br>  
      <div v-for="(rec, index) in recordings" :key="index">
        {{rec.userId}}
        <br>
        <div v-for="(area, index2) in areas" :key="'a'+index2">
          {{area.label}} {{(rec.areaSamples[index2]/rec.x.length*100).toFixed(2)}}%, 
        </div>
        <br>        
      </div>
    </div>
    
    
  </div>
</template>

<script>
import h337 from 'heatmap.js'
import VueDraggableResizable from 'vue-draggable-resizable'
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import 'media-chrome'
import axios from 'axios'

let motionPositions = []
let slidingWindowDataPoints = []
let localThis
let sample = 0
let lastSample
let failsafe
let sampleInterval

export default {
  name: 'HeatmapTest',
  components: {
    VueDraggableResizable
  },
  data: function(){
    return{
      areas :[],
      dataMin: 0,
      dataMax: 10,
      heatmapInstance: undefined,
      rawData: [],

      recorderInterval: undefined,
      replayInterval: undefined,
      replayTime: 0,      
      sampleRate: 12,
      mouseX: 0,
      mouseY: 0,
      gazeX: 0,
      gazeY: 0,
      recordingX: [],
      recordingY: [],
      recordings: [],
      videoBounds: Object,
      testRecordingJson: undefined,
      drawScanpath: false,
      drawMotionTrail: true,
      motionTrailLength: 20,
      motionTrailRadius: 10,
      slidingWindow: true,
      slidingWindowSamples: 12,
      labelPercentages: false,      
      labelPercentagesOverall: false,      

      sessionId: undefined,
      segmentId: undefined,
      itemId: undefined
    }
  },  
  computed: {
    env: function(){
      return process.env.VUE_APP_ENV
    },
    videoSource(){
      return this.env == 'local' ? "BC_LR_01.mp4" : "https://s3.eu-central-1.amazonaws.com/dev.public.avris.io/balint-test1/BC_LR_01.mp4"
    },
    covered(){
      return (this.rawData.filter(d => d.x > 90 && d.x < 170 && d.y > 100 && d.y < 230).length / this.rawData.length * 100).toFixed(2)
    }
  },
  methods:{  
    addArea(){
      this.areas.push({
        w: 70,
        h: 90,        
        x: 0,
        y: 0,
        wN: 100/this.videoBounds.width,
        hN: 100/this.videoBounds.height,
        xN: 0,
        yN: 0,
        label: `label${this.areas.length + 1}`,
        liveCoverage: 0,
        aggregatedCoverage: 0
      })
    },
    removeArea(index){
      this.areas.splice(index, 1)
    },
    recalcArea(){
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      let area = this.areas[areaindex]
      area.liveCoverage = slidingWindowDataPoints.filter(d => 
        d.x > area.x && d.x < (area.x + area.w) && d.y > area.y && d.y < (area.y + area.h)
      ).length / slidingWindowDataPoints.length * 100
    },
    onAreaResize: function (x, y, width, height) {
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      //console.log(areaindex)
      this.areas[areaindex].x = x
      this.areas[areaindex].y = y
      this.areas[areaindex].w = width
      this.areas[areaindex].h = height
      this.areas[areaindex].wN = width/localThis.videoBounds.width,
      this.areas[areaindex].hN = height/localThis.videoBounds.height,
      this.areas[areaindex].xN = x/localThis.videoBounds.width,
      this.areas[areaindex].yN = y/localThis.videoBounds.height
    },
    onAreaDrag: function (x, y) {
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      this.areas[areaindex].x = x
      this.areas[areaindex].y = y
      this.areas[areaindex].xN = x/localThis.videoBounds.width,
      this.areas[areaindex].yN = y/localThis.videoBounds.height      
    },
    startRecording(){
      this.recorderInterval = setInterval(() => {
        //console.log('mouseX: ' + this.mouseX + ' mouseY: ' + this.mouseY)

        // TODO calc actual progress milliseconds from video seek value

        // calculate gaze relative to video
        if(this.mouseX >= this.videoBounds.x && this.mouseX <= (this.videoBounds.x + this.videoBounds.width)
          && this.mouseY >= this.videoBounds.y && this.mouseY <= (this.videoBounds.y + this.videoBounds.height))
        {
          this.gazeX = Math.round( (this.mouseX - this.videoBounds.x)/this.videoBounds.width * 1e4 ) / 1e4     
          this.gazeY = Math.round( (this.mouseY - this.videoBounds.y)/this.videoBounds.height * 1e4 ) / 1e4 
        }
        //console.log('gazeX: ' + this.gazeX + ' gazeY: ' + this.gazeY)
        this.recordingX.push(this.gazeX)
        this.recordingY.push(this.gazeY)

      }, Math.round(1000/this.sampleRate));

      this.recordingX = []
      this.recordingY = []
      this.$refs.video.play()

    },
    stopRecording(){
     clearInterval(this.recorderInterval)
     this.recorderInterval = undefined
     this.$refs.video.pause()  
     this.$refs.video.currentTime = 0
     let tmp = [
      {
        item: 12345,
        session: 123,
        device: "PA7950RGF6190584B",
        x: this.recordingX,
        y: this.recordingY
      }   
     ]
     document.getElementById('testJson').innerHTML = JSON.stringify(tmp)
    },
    loadRecordings(){
      let parsed = JSON.parse(document.getElementById('testJson').value)
      parsed.forEach((r,i) => {
        r.userId = (i+'?'),
        r.areaSamples = Array(this.areas.length).fill(0)
      })
      this.recordings = parsed
    },
    async downloadRecordings(){
      this.recordings.length = 0

      let url = `sessions/${this.sessionId}/recordings`
      if(this.segmentId){
        url += `?segment=${this.segmentId}`
        if(this.itemId)
          url += `&item=${this.itemId}`
      }
      let resp = await axios({ url: url, method: 'GET' }) 

      let promises = resp.data.map(rec => axios.get(rec.blobUrl, {transformRequest: (data, headers) => {
          delete headers.common['Authorization'];
          return data;
        }})
      )
      //const filenameRegex = new RegExp("\\/\\d+_\\d+_.*\\.json")

      await Promise.all(promises) 
        .then((data) => {
          data.forEach((rec, i) => {
            //let userId = rec.config.url.match(filenameRegex)[0].split('_')[2]            
            // rec.data.userId = userId
           
            resp.data[i].x = rec.data.x
            resp.data[i].y = rec.data.y            
            this.recordings.push(resp.data[i])
          });
        })
        .catch((err) => {
          console.log(err)
        })

      document.getElementById('testJson').innerHTML = JSON.stringify(this.recordings)
    },
    replayRecording(){
      let interval = Math.round(1000/this.sampleRate)    
      this.replayTime = 0
      this.$refs.video.play()

      // reset heatmap
      this.heatmapInstance.setData({
        max: this.dataMax,
        min: this.dataMin,
        data: []
      })

      var mc = document.getElementById("motionTrailCanvas");
      var mCtx = mc.getContext("2d");
      motionPositions = []

      var c = document.getElementById("scanpathCanvas");
      var ctx = c.getContext("2d");
      ctx.clearRect(0, 0, c.width, c.height);
      ctx.beginPath();
      ctx.moveTo(0, 0);  
      //ctx.lineWidth = 0.5    

      this.replayInterval = setInterval(() => {
             
        const elapsed = Math.round(this.replayTime/interval)
        if(elapsed >= this.recordingX.length){
          clearInterval(this.replayInterval)
          this.$refs.video.pause() 
          return
        }        

        let p = {
          x: this.recordingX[elapsed]*this.videoBounds.width,
          y: this.recordingY[elapsed]*this.videoBounds.height,
          value: 1
        }

        if(this.drawScanpath){
          ctx.lineTo(p.x, p.y);
          ctx.stroke();
        }

        if(this.drawMotionTrail){
          motionPositions.push({
            x: p.x,
            y: p.y
          })        
          //get rid of first item
          if (motionPositions.length > this.motionTrailLength) {
            motionPositions.shift()
          }

          mCtx.clearRect(0, 0, mc.width, mc.height);
          //console.log(motionPositions.length)
          for (var i = 0; i < motionPositions.length; i++) {
            let ratio = (i + 1) / motionPositions.length;
            mCtx.beginPath();
            mCtx.arc(motionPositions[i].x, motionPositions[i].y, this.motionTrailRadius*ratio, 0, 2 * Math.PI, true);
            mCtx.fillStyle = "rgba(204, 102, 153, " + ratio / 2 + ")"
            mCtx.fill();
          }

        }

        this.heatmapInstance.addData(p)    
        this.replayTime += interval
      }, interval)
    },
    seekRecordings(/*ev*/){
      // use ev.target.currentTime or this.$refs.video.currentTime
      // reset heatmap to current sample when seek happens      
      slidingWindowDataPoints.length = 0
      // render current sample if video paused (for example if prof is just clicking around and examining individual video frames)
      if(this.$refs.video.paused)
        this.renderSamples()
    },
    playRecordings(){
      sampleInterval = Math.round(1000/this.sampleRate)
      this.replayInterval = setInterval(this.renderSamples, sampleInterval)
    },
    pauseRecordings(){
      clearInterval(this.replayInterval)
    },
    renderSamples(){      
      sample = Math.round(this.$refs.video.currentTime*1000/sampleInterval)
      //console.log(this.$refs.video.currentTime + ' ' + sample + ' ' + sampleInterval)

      // no samples available 
      if(sample >= failsafe || lastSample == sample){     
        console.log('skipping duplicate sample render' )          
        return
      } 
      // avoid rendering same sample twice (edge-case if intervals would overlap for whatever reason)
      lastSample = sample
      
      // current samples
      let dataPoints = this.recordings.map(r =>
        ({
          x: r.x[sample]*this.videoBounds.width,
          y: r.y[sample]*this.videoBounds.height,
          value: 1
        })
      )

      // maintain samples in sliding window
      if(this.slidingWindow){
        slidingWindowDataPoints.push(...dataPoints)        
        // push out "old" samples from window
        // TODO use Queue.js if performance will be an issue, although heatmap.js uses arrays anyway
        // TODO2 here we assume all recordings have the same amount of samples, and we calculate how many samples that means in total in our window.
        // but this could act weird at the end of videos if not all users have equal number of samples. probably minor thing, but worth keeping note of this
        if (slidingWindowDataPoints.length > this.slidingWindowSamples*this.recordings.length) {              
          slidingWindowDataPoints.splice(0, this.recordings.length)
        }

        // render heatmap
        this.heatmapInstance.setData({
          max: this.dataMax,
          min: this.dataMin,
          data: slidingWindowDataPoints
        })

        // live area coverage calculations
        this.areas.forEach(area => {
          area.liveCoverage = slidingWindowDataPoints.filter(d => 
            d.x > area.x && d.x < (area.x + area.w) && d.y > area.y && d.y < (area.y + area.h)
          ).length / slidingWindowDataPoints.length * 100
        });
      }
    },
    replayRecordings(){
      let interval = Math.round(1000/this.sampleRate)    
      this.replayTime = 0       
      this.$refs.video.currentTime = 0
      this.$refs.video.play()

      // TODO ********************
      // refactor this to be reactive on video play/pause whenver video is playing, start this loop, pause when paused, etc...

      // reset heatmap
      this.heatmapInstance.setData({
        max: this.dataMax,
        min: this.dataMin,
        data: []
      }) 

      const failsafe = Math.min(...this.recordings.map(r => r.x.length))
      console.log("shortest recording: " + failsafe + "samples")
      this.replayInterval = setInterval(() => {
             
        const elapsed = Math.round(this.replayTime/interval)
        if(elapsed >= failsafe){
          clearInterval(this.replayInterval)
          this.$refs.video.pause() 
          return
        }        

        // current samples
        let dataPoints = this.recordings.map(r =>
          ({
            x: r.x[elapsed]*this.videoBounds.width,
            y: r.y[elapsed]*this.videoBounds.height,
            value: 1
          })
        )

        if(this.slidingWindow){
          slidingWindowDataPoints.push(...dataPoints)        
          // push out "old" samples from window
          // TODO use Queue.js if performance will be an issue, although heatmap.js uses arrays anyway
          // TODO2 here we assume all recordings have the same amount of samples, and we calculate how many samples that means in total in our window.
          // but this could act weird at the end of videos if not all users have equal number of samples. probably minor thing, but worth keeping note of this
          if (slidingWindowDataPoints.length > this.slidingWindowSamples*this.recordings.length) {              
            slidingWindowDataPoints.splice(0, this.recordings.length)
          }

          this.heatmapInstance.setData({
            max: this.dataMax,
            min: this.dataMin,
            data: slidingWindowDataPoints
          })

          this.areas.forEach(area => {
            area.liveCoverage = slidingWindowDataPoints.filter(d => 
              d.x > area.x && d.x < (area.x + area.w) && d.y > area.y && d.y < (area.y + area.h)
            ).length / slidingWindowDataPoints.length * 100
          });
        }
        else{          
          // this is the one where it can slow down significantly so we need a sliding window and use setData
          this.heatmapInstance.addData(dataPoints)  
        }

        this.replayTime += interval
      }, interval)
    },
    aggregateAreaCoverage(){
      // init
      let totalSamples = 0
      this.areas.forEach(area => {
        area.coveredSampleCount = 0
      })
      // calc avg for each area, how many samples were in total inside the area
      this.recordings.forEach(rec => {
        rec.areaSamples = Array(this.areas.length).fill(0)
        
        for (let i = 0; i < rec.x.length; i++) {          
          this.areas.forEach((area,index) => {
            if(rec.x[i] > area.xN && rec.x[i] < (area.xN + area.wN) && rec.y[i] > area.yN && rec.y[i] < (area.yN + area.hN)){
              area.coveredSampleCount++
              rec.areaSamples[index]++
            }
          })
          totalSamples++
        }   
      })

      this.areas.forEach(area => {
        area.aggregatedCoverage = area.coveredSampleCount / totalSamples * 100
      })
    },
    getInternalData(){
      console.log(this.heatmapInstance.getData())
    },
    async addDataPoint(e){
      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top;  //y position within the element.
      console.log("Left? : " + x + " ; Top? : " + y + "."); 
      let p = {
        x: x,
        y: y,
        value: 10
      }
      this.heatmapInstance.addData(p)     
      this.rawData.push(p) //= this.heatmapInstance.getData().data
    },  
    async setScale(){
      this.heatmapInstance.setDataMax(this.dataMax)
      this.heatmapInstance.setDataMin(this.dataMin)
      console.log("max: " + this.dataMax + " min: " + this.dataMin)
    },
    async animate(){
      this.rawData = [
        { x: 20, y: 20, value: 1, dx: 6, dy: 1, dv: 0.1 },
        { x: 220, y: 40, value: 5, dx: 12, dy: 3, dv: 0.1  },
        { x: 20, y: 60, value: 2, dx: 1, dy: 1, dv: 0.2  },
        { x: 200, y: 80, value: 3, dx: 2, dy: 2, dv: 0.1  },
        { x: 20, y: 100, value: 2, dx: 1, dy: 8, dv: 0.3  },
        { x: 20, y: 120, value: 6, dx: 3, dy: 1, dv: 0.3  },
        { x: 100, y: 140, value: 3, dx: 1, dy: 5, dv: 0.1  },
        { x: 20, y: 160, value: 8, dx: 1, dy: 1, dv: 0.1  },
        { x: 150, y: 180, value: 3, dx: 5, dy: 1, dv: 0.3  },
        { x: 200, y: 200, value: 9, dx: 1, dy: 4, dv: 0.2  },
      ]
      
      setInterval(() => {
        this.rawData.forEach(p => {
          if(p.x + p.dx >= 600 || p.x + p.dx <= 0)
            p.dx *= -1
          p.x += p.dx

          if(p.y + p.dy >= 250 || p.y + p.dy <= 0)
            p.dy *= -1
          p.y += p.dy

          if(p.value + p.dv >= this.dataMax || p.value + p.dv <= this.dataMin)
            p.dv *= -1
          p.value += p.dv
        });

        this.heatmapInstance.setData({
          max: this.dataMax,
          min: this.dataMin,
          data: this.rawData
        })
      },50)
    },
    async init(){
      console.log('drawing heatmap')
      this.heatmapInstance = h337.create({
        // only container is required, the rest will be defaults
        container: document.querySelector('.heatmap')
      });

      // // // now generate some random data
      // // var points = []
      // // var max = 0
      // // var width = 840
      // // var height = 400
      // // var len = 200

      // // while (len--) {
      // //   var val = Math.floor(Math.random()*100)
      // //   max = Math.max(max, val);
      // //   var point = {
      // //     x: Math.floor(Math.random()*width),
      // //     y: Math.floor(Math.random()*height),
      // //     value: val
      // //   };
      // //   points.push(point)
      // // }
      // heatmap data format
      // var data = {
      //   max: max,
      //   data: points
      // };
      // if you have a set of datapoints always use setData instead of addData
      // for data initialization
      //this.heatmapInstance.setData(data);
      
      // var dataPoint1 = {
      //   x: 100, // x coordinate of the datapoint, a number
      //   y: 100, // y coordinate of the datapoint, a number
      //   value: 30 // the value at datapoint(x, y)
      // }
      // var dataPoint2 = {
      //   x: 150, // x coordinate of the datapoint, a number
      //   y: 100, // y coordinate of the datapoint, a number
      //   value: 70 // the value at datapoint(x, y)
      // }
      // var dataPoints = [dataPoint1, dataPoint2]
      // //this.heatmapInstance.addData(dataPoints)
      this.setScale()

    },    
  },
  mounted(){
    this.init()
    document.onmousemove = (e) => {
      //console.log("mouse location:", e.clientX, e.clientY)      
      this.mouseX = e.clientX
      this.mouseY = e.clientY
    }

    this.videoBounds = document.getElementById('video').getBoundingClientRect()
    console.log(this.videoBounds)

    this.addArea()
    localThis = this

    this.$refs.video.onseeked = this.seekRecordings
    this.$refs.video.onplay = this.playRecordings
    this.$refs.video.onpause = this.pauseRecordings
  }
}
</script>

<style lang="scss">
.heatmap{
  width: 600px;
  height: 300px;
  border: 1px solid greenyellow;
  position: absolute !important;
  top: 180px;
  left: 0px;
  z-index: 10;

  &.recording{
    cursor: crosshair;
  }
}

#scanpathCanvas, #motionTrailCanvas{  
  position: absolute;
  width: 600px;
  height: 300px;
  top: 180px;
  left: 0px;
  z-index: 12;

  &.recording{
    cursor: crosshair;
  }
}

// #area1{
//   border: 2px dashed red;
//   position: absolute;
//   z-index: 9;
//   top: 180px;
//   left: 90px;  
//   width: 80px;
//   height: 130px;
// }

video{
  //position: absolute;
  //top: 180px;
  //left: 0px;
  width:600px;
  height: 300px;

  &.recording{
    cursor: crosshair;
  }
}

.mediacontroller{
  position: absolute;
  top: 180px;
  width: 600px;  
  height: 344px;
  left: 0px;
}

media-control-bar{
  opacity:1;
}

#testJson{
  position: absolute;
  top: 530px;
  min-height: 300px;
  max-width: 600px;
  left: 0;
}

.areas{
  width: 600px;
  height: 300px;
  position: absolute !important;
  top: 180px;
  left: 0px;
  z-index: 20;

  .vdr{
    border: 1px dashed greenyellow;
    
    span.label{
      background-color: greenyellow;
      //color: white;
      padding: 0 2px;
      font-size: 10px;
      vertical-align: top;
    }
  }
}
.arealist{
  width: 400px;
  min-height: 100px;
  position: absolute;
  top: 180px;
  left: 650px;
  border:1px solid rgb(221, 221, 221);

  input[type=text]{
    width: 100px;
    height: 20px;
  }
}
.userlist{
  width: 300px;
  min-height: 100px;
  position: absolute;
  top: 180px;
  left: 1100px;
  border:1px solid rgb(221, 221, 221);

  input{
    width: 100px;
    height: 20px;
  }
}

.loader{
  input{
    max-width: 50px;
  }
}


</style>